import { InferType, object, string } from 'yup';

export const loginFormSchema = object({
    // Credentials
    email: string()
        .label('Email Address')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required(),
    password: string().label('Password').required(),
});

type SignInFormModel = InferType<typeof loginFormSchema>;

export default SignInFormModel;
