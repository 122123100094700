import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import ForgotPasswordFormModel from '@/models/ForgotPasswordFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';
import FormStatus from '../FormStatus';

const dot = makeDot<ForgotPasswordFormModel>();

export default function ForgotPasswordForm() {
    return (
        <Form>
            <InputGroup name={dot('email')} title="Email">
                <TextInput
                    placeholder="Enter your email address"
                    type="email"
                    inputMode="email"
                />
            </InputGroup>
            <p className="text-thin py-6 text-center text-xs">
                An email will be sent to your address to reset your password
            </p>
            <FormStatus />
            <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Button type="submit" className="hidden px-12 sm:flex">
                    Send
                </Button>
                <Button type="submit" className="flex px-12 sm:hidden" full>
                    Send
                </Button>
                <div className="flex sm:space-x-2">
                    <span>or</span>
                    <Link to="/sign-in" className="ml-2 text-primary underline">
                        Cancel
                    </Link>
                </div>
            </div>
        </Form>
    );
}
