import { InferType, boolean, object, string } from 'yup';

export const personalizeGiftCardFormSchema = object({
    senderFirstName: string().label('First name').required(),
    senderEmail: string()
        .label('Email')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required(),
    receiverFirstName: string().label('First name').required(),
    receiverEmail: string()
        .label('Email')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required(),
    receiverMessage: string().label('Message'),
    deliveryAt: string().label('Date').required(),
    isAnotherReceiver: boolean().default(false),
    bonusReceiverFirstName: string()
        .label('First name')
        .when('isAnotherReceiver', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    bonusReceiverEmail: string()
        .label('Email')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .when('isAnotherReceiver', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    bonusReceiverMessage: string()
        .label('Message')
        .when('isAnotherReceiver', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    bonusDeliveryAt: string()
        .label('Date')
        .when('isAnotherReceiver', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
});

type PersonalizeGiftCardFormModel = InferType<
    typeof personalizeGiftCardFormSchema
>;

export default PersonalizeGiftCardFormModel;
