import { InferType, object, string } from 'yup';
import postcode from './rules/postCode';

export const getStartedFormSchema = object({
    email: string()
        .label('Email address')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required('Email is required'),
    shippingPostcode: string()
        .label('Postcode')
        .test(
            postcode(
                "I'm sorry, it doesn't look like we can deliver to your postcode yet."
            )
        )
        .required(),
    shippingDateStartedAt: string()
        .label('Delivery date')
        .when('shippingStartedAt', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    shippingStartedAt: string().label('Delivery date').required(),
    deliveryScheduleId: string().label('Delivery time').required(),
    code: string().label('code'),
});

type GetStartedFormModel = InferType<typeof getStartedFormSchema>;

export default GetStartedFormModel;
