import { subDays } from 'date-fns';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/common/Button';
import {
    CalendarIcon,
    CheckPrimarySolid,
    ChevronLeftIcon,
    GiftBoxIcon,
} from '@/components/common/Icons';
import { SingleCheckBox } from '@/components/inputs/CheckBoxGroup';
import InputGroup from '@/components/inputs/InputGroup';
import TextArea from '@/components/inputs/TextArea';
import TextInput from '@/components/inputs/TextInput';
import PersonalizeGiftCardFormModel from '@/models/PersonalizeGiftCardFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';

type Props = {
    amount?: any;
    isGiftCardPromotion?: any;
};

const dot = makeDot<PersonalizeGiftCardFormModel>();

export default function PersonalizeGiftCardForm({
    amount,
    isGiftCardPromotion,
}: Props) {
    const navigate = useNavigate();
    const formik = useFormikContext();
    const { setFieldValue } = formik;
    const { values } = useFormikContext<PersonalizeGiftCardFormModel>();
    return (
        <Form className="py-2 px-4 md:mx-auto md:w-[32rem]">
            <div className="flex items-center justify-between">
                <div
                    tabIndex={0}
                    role="button"
                    className="flex justify-around transition-colors hover:text-primary"
                    onClick={() => {
                        navigate('/gift-cards');
                    }}
                    onKeyDown={(event) => {
                        event.preventDefault();
                        if (event.code === 'Enter' || event.code === 'Space') {
                            navigate('/gift-cards');
                        }
                    }}
                >
                    <ChevronLeftIcon className="mt-1 mr-4" />
                    Modify your choice
                </div>
                <div className="font-xl relative flex border border-primary py-2 px-2 font-bold text-primary shadow-[3px_4px_0px_0px_rgba(0,0,0)]">
                    ${amount.toFixed(2)}
                    <CheckPrimarySolid
                        role="img"
                        aria-label="Checkmark"
                        className="mt-1 ml-4 h-4 w-4"
                    />
                </div>
            </div>
            <div className="mt-8 border border-black py-2 px-4 sm:py-4 sm:px-8">
                <div className="w-full pt-3.5 text-base font-bold uppercase">
                    Who should we say it&apos;s from?
                </div>
                <InputGroup className="mt-5" name={dot('senderFirstName')}>
                    <TextInput placeholder="Your first name" />
                </InputGroup>
                <InputGroup className="mt-5" name={dot('senderEmail')}>
                    <TextInput placeholder="Your email" inputMode="email" />
                </InputGroup>
                <div className="w-full pt-3.5 text-base font-bold uppercase">
                    Recipient
                </div>
                <InputGroup className="mt-5" name={dot('receiverFirstName')}>
                    <TextInput placeholder="Recipient's first name" />
                </InputGroup>
                <InputGroup className="mt-5" name={dot('receiverEmail')}>
                    <TextInput
                        placeholder="Recipient's email"
                        inputMode="email"
                    />
                </InputGroup>
                <div className="flex justify-around">
                    <div className="w-full pt-3.5 text-base font-bold uppercase">
                        Personalised message
                    </div>
                    <div className="pt-3.5 text-base">Optional</div>
                </div>
                <InputGroup className="" name={dot('receiverMessage')}>
                    <TextArea placeholder="I hope you enjoy your premium meat & wild-caught seafood! Bon Appetit!" />
                </InputGroup>
                <div className="w-full pt-3.5 text-base font-bold uppercase">
                    When would you like this to be received?
                </div>
                <InputGroup name={dot('deliveryAt')}>
                    <div className="relative">
                        <DatePicker
                            className="form-input w-full border-black"
                            selected={
                                values.deliveryAt
                                    ? new Date(values.deliveryAt)
                                    : null
                            }
                            onChange={(date: any) =>
                                setFieldValue(dot('deliveryAt'), date)
                            }
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select a date"
                            minDate={subDays(new Date(), 0)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <CalendarIcon />
                        </div>
                    </div>
                </InputGroup>
                {isGiftCardPromotion?.bonus > 0 && (
                    <div className="mt-9 flex justify-between bg-gray-200 py-2 px-2 sm:px-4">
                        <div className="flex">
                            <InputGroup
                                className="my-auto pt-0.5"
                                name={dot('isAnotherReceiver')}
                            >
                                <SingleCheckBox label="" />
                            </InputGroup>
                            <span className="my-auto mr-2 w-full text-xs sm:text-base">
                                Send the bonus gift card to a different person
                            </span>
                        </div>
                        <div className="my-auto flex bg-primary py-1 px-2">
                            <span className="font-bold text-white">
                                $
                                {(
                                    Math.round(
                                        (amount / 100) *
                                            isGiftCardPromotion.bonus
                                    ) / 100
                                ).toFixed(2)}
                            </span>
                            <GiftBoxIcon className="my-auto ml-2 h-5 w-5 text-white" />
                        </div>
                    </div>
                )}
                {values?.isAnotherReceiver && (
                    <div>
                        <div className="w-full pt-3.5 text-base font-bold uppercase">
                            Who is the recipient?
                        </div>
                        <InputGroup
                            className="mt-5"
                            name={dot('bonusReceiverFirstName')}
                        >
                            <TextInput placeholder="Recipient's first name" />
                        </InputGroup>
                        <InputGroup
                            className="mt-5"
                            name={dot('bonusReceiverEmail')}
                        >
                            <TextInput
                                placeholder="Recipient's email"
                                inputMode="email"
                            />
                        </InputGroup>
                        <div className="flex justify-around">
                            <div className="w-full pt-3.5 text-base font-bold uppercase">
                                Personalised message
                            </div>
                            <div className="pt-3.5 text-base">Optional</div>
                        </div>
                        <InputGroup
                            className=""
                            name={dot('bonusReceiverMessage')}
                        >
                            <TextArea placeholder="I hope you enjoy your premium meat & wild-caught seafood! Bon Appetit!" />
                        </InputGroup>
                        <div className="w-full pt-3.5 text-base font-bold uppercase">
                            When would you like this to be received?
                        </div>
                        <InputGroup name={dot('bonusDeliveryAt')}>
                            <div className="relative">
                                <DatePicker
                                    className="form-input w-full border-black"
                                    selected={
                                        values.bonusDeliveryAt
                                            ? new Date(values.bonusDeliveryAt)
                                            : null
                                    }
                                    onChange={(date: any) =>
                                        setFieldValue(
                                            dot('bonusDeliveryAt'),
                                            date
                                        )
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select a date"
                                    minDate={subDays(new Date(), 0)}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <CalendarIcon />
                                </div>
                            </div>
                        </InputGroup>
                    </div>
                )}
            </div>
            <div className="mt-2 flex flex-col items-center sm:mt-4">
                <Button type="submit" wide>
                    Proceed to checkout
                </Button>
            </div>
        </Form>
    );
}
