import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect } from 'react';
import Button from '@/components/common/Button';
import { CheckPrimarySolid } from '@/components/common/Icons';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import GiftCardFormModel from '@/models/GiftCardFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';

type Props = {
    amount?: any;
    setAmount?: any;
};

const dot = makeDot<GiftCardFormModel>();

const options = [
    { id: 1, title: '$10', amount: 1000 },
    { id: 2, title: '$50', amount: 5000 },
    { id: 3, title: '$100', amount: 10000 },
    { id: 4, title: '$200', amount: 20000 },
];

const handleKeyDown = (e: any) => {
    const charCode = e.which ? e.which : e.keyCode;
    const inputValue = e.target.value;

    if ([8, 9, 37, 38, 39, 40, 46].includes(charCode)) {
        return;
    }

    if (
        charCode !== 190 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 96 || charCode > 105)
    ) {
        e.preventDefault();
        return;
    }

    if (charCode === 190 && inputValue.includes('.')) {
        e.preventDefault();
        return;
    }

    const decimalIndex = inputValue.indexOf('.');
    if (
        decimalIndex !== -1 &&
        charCode !== 190 &&
        inputValue.length - decimalIndex > 2
    ) {
        e.preventDefault();
    }
};

export default function GiftCardForm({ amount, setAmount }: Props) {
    const formik = useFormikContext();
    const { setFieldValue } = formik;
    const { values } = useFormikContext<GiftCardFormModel>();

    useEffect(() => {
        if (
            amount > 0 &&
            options.some((option) => option.amount / 100 === amount)
        ) {
            setFieldValue(dot('amount'), amount);
        }
    }, [amount, setFieldValue]);

    return (
        <>
            <div className="mt-12 flex flex-col items-center">
                <p className="text-center text-lg font-bold text-primary">
                    Choose the amount you would like to offer
                </p>
                <p>*Minimum purchase of $10</p>
            </div>
            <Form className="mt-12 flex flex-col items-center">
                <Field name={dot('amount')}>
                    {({ field, form }: FieldProps) => (
                        <RadioGroup.Root
                            {...field}
                            value={amount}
                            onValueChange={(value) => {
                                form.setFieldValue('amount', value);
                                form.setFieldValue('customAmount', undefined);
                                setAmount(value);
                            }}
                            className="relative grid grid-cols-2 flex-col gap-4 sm:grid-cols-4"
                        >
                            {options?.map((e: any) => (
                                <RadioGroup.Item
                                    key={e.id}
                                    id={e.id}
                                    value={e.amount}
                                >
                                    <div
                                        className={clsx(
                                            'relative flex shrink-0 border border-black py-4 px-10',
                                            e.amount === amount &&
                                                'font-xl font-bold text-primary shadow-[3px_4px_0px_0px_rgba(0,0,0)]'
                                        )}
                                    >
                                        {e.title}
                                        <RadioGroup.Indicator>
                                            <CheckPrimarySolid
                                                role="img"
                                                aria-label="Checkmark"
                                                className="absolute ml-2 mt-1 h-4 w-4"
                                            />
                                        </RadioGroup.Indicator>
                                    </div>
                                </RadioGroup.Item>
                            ))}
                        </RadioGroup.Root>
                    )}
                </Field>
                <span className="mt-12">or enter any amount</span>
                <InputGroup name={dot('customAmount')}>
                    <TextInput
                        type="number"
                        height={14}
                        placeholder="0"
                        min="10"
                        dollarInput
                        onKeyUp={() => {
                            if (values.customAmount! > 0) {
                                setAmount(values.customAmount! * 100);
                            }
                        }}
                        onBlur={() => {
                            if (values.customAmount! > 10) {
                                if (
                                    values.customAmount &&
                                    options.some(
                                        (option) =>
                                            option.amount / 100 ===
                                            values.customAmount
                                    )
                                ) {
                                    setFieldValue(dot('customAmount'), '');
                                }
                            } else if (values.customAmount! <= 10) {
                                setFieldValue(dot('customAmount'), '');
                                setAmount(1000);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        inputMode="decimal"
                    />
                </InputGroup>
                <div className="mt-2 flex flex-col items-center sm:mt-4">
                    <Button type="submit" wide>
                        Next step
                    </Button>
                </div>
            </Form>
        </>
    );
}
