import { InferType, boolean, mixed, number, object, string } from 'yup';
import StateType from '@/types/StateType';
import expiration from './rules/creditCard';
import passwordConfirmation from './rules/passwordConfirmation';
import phoneNumber from './rules/phoneNumber';
import postcode from './rules/postCode';

export const isBusiness = (shippingAddressType: string) =>
    shippingAddressType === 'business';

export const isApartment = (shippingAddressType: string) =>
    shippingAddressType === 'apartment';

export const parseAddress = (shippingAddressLine_1: string, suite?: string) => {
    if (!suite) {
        return shippingAddressLine_1;
    }
    const addressOneArray = shippingAddressLine_1
        .match(/[A-Za-z]+|\d+|\s+|\//g)
        ?.filter((e) => /\d/.test(e));

    const addressTwoArray = suite
        .match(/[A-Za-z]+|\d+|\s+|\//g)
        ?.filter((e) => /\d/.test(e));

    if ((shippingAddressLine_1.match(/-/g) || []).length > 1) {
        shippingAddressLine_1 = shippingAddressLine_1.replace('-', '');
    }

    if (
        addressOneArray!.length > 1 &&
        addressTwoArray!.length < 2 &&
        addressTwoArray?.every((e) => addressOneArray?.includes(e))
    ) {
        return shippingAddressLine_1
            .replace(suite, '')
            .replace(/[/\\]/g, '')
            .replace(/^-\s*|\s+-\s*/g, '')
            .trim();
    }
    return shippingAddressLine_1;
};

export const checkoutFormSchema = object({
    firstName: string().label('First Name').required(),
    lastName: string().label('Surname').required(),
    phone: string()
        .label('Phone')
        .test(phoneNumber('Phone is invalid'))
        .required(),
    password: string()
        .label('Password')
        .when('hasPassword', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    passwordConfirmation: string()
        .label('Password Confirmation')
        .test(passwordConfirmation('password', 'Password do not match'))
        .when('hasPassword', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    email: string(),
    hasKnife: boolean().default(false),
    adult: boolean()
        .label('I confirm that I am 18 years old, or older.')
        .when('hasKnife', {
            is: true,
            then: (s) => s.isTrue('You must be an adult to order a knife.'),
        })
        .default(false),
    knifeDisclaimer: boolean()
        .label(
            'I agree to ButcherCrowds terms & conditions, and have read, understand, and acknowledge the disclaimers associated with the chefs knife I am ordering today.'
        )
        .when('hasKnife', {
            is: true,
            then: (s) =>
                s.isTrue(
                    'You must agree to ButcherCrowds terms & conditions, and have read, understand, and acknoledge the disclaimers associated with the chefs knife you are ordering today.'
                ),
        })
        .default(false),
    sms: boolean()
        .label('SMS')
        .when('hasSms', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    newsletter: boolean()
        .label('Newsletter')
        .when('hasNewsletter', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    shippingAddressLine_1: string().label('Street address').required(),
    suite: string()
        .label('Apartment, unit, or suite number')
        .when('shippingAddressType', {
            is: isApartment,
            then: (s) => s.required(),
        }),
    shippingCity: string().label('Suburb').required(),
    postcode: string().test(postcode('Post code invalid')).required(),
    shippingAddressType: string().label('Residence').required(),
    companyName: string()
        .label('Company name')
        .when('shippingAddressType', {
            is: isBusiness,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    country: string().label('Country').default('Australia').required(),
    shippingState: mixed<StateType>().label('State').required(),
    deliveryInstructions: string(),
    useShippingAddress: boolean().label('Billing').default(true),

    existingCard: boolean().default(false),
    paymentMethod: string().when('existingCard', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),

    number: string()
        .label('Credit card')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    expiration: string().when('existingCard', {
        is: false,
        then: (s) => s.test(expiration()).required(),
        otherwise: (s) => s.strip(),
    }),
    cvc: string()
        .label('CVC')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),

    // Billing Address
    billingAddressLine_1: string()
        .label('Address')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingSuite: string().label('Apartment, unit, or suite number'),
    billingCity: string()
        .label('Suburb')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingPostcode: string()
        .label('Postcode')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingAddressType: string()
        .label('Residence')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingCompanyName: string()
        .label('Company name')
        .when('billingAddressType', {
            is: isBusiness,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingCountry: string()
        .label('Country')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    billingState: string()
        .label('State')
        .when('useShippingAddress', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),

    frequency: number(),
    hasPassword: boolean(),
    hasSms: boolean(),
    hasNewsletter: boolean(),
    discount: string(),
    hasChoices: boolean(),
    choices: mixed<any[]>().when('hasChoices', {
        is: true,
        then: (s) => s.required('You must choose a product.'),
        otherwise: (s) => s.strip(),
    }),
    // giftCards: mixed<any[]>(),
});

type CheckoutFormModel = InferType<typeof checkoutFormSchema>;

export default CheckoutFormModel;
