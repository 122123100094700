import { InferType, boolean, mixed, object, string } from 'yup';
import StateType from '@/types/StateType';
import expiration from './rules/creditCard';
import phoneNumber from './rules/phoneNumber';
import postcode from './rules/postCode';

export const isBusiness = (billingAddressType: string) =>
    billingAddressType === 'business';

export const checkoutOrderFormSchema = object({
    firstName: string().label('First Name').required(),
    lastName: string().label('Surname').required(),
    phone: string()
        .label('Phone')
        .test(phoneNumber('Phone is invalid'))
        .required(),
    sms: boolean()
        .label('SMS')
        .when('hasSms', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    newsletter: boolean()
        .label('Newsletter')
        .when('hasNewsletter', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    billingAddressLine_1: string().label('Street address').required(),
    suite: string().label('Apartment, unit, or suite number'),
    billingCity: string().label('Suburb').required(),
    postcode: string().test(postcode('Post code invalid')).required(),
    billingAddressType: string().label('Residence').required(),
    companyName: string()
        .label('Company name')
        .when('billingAddressType', {
            is: isBusiness,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    country: string().label('Country').default('Australia').required(),
    billingState: mixed<StateType>().label('State').required(),
    existingCard: boolean().default(false),
    paymentMethod: string().when('existingCard', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),
    number: string()
        .label('Credit card')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    expiration: string().when('existingCard', {
        is: false,
        then: (s) => s.test(expiration()).required(),
        otherwise: (s) => s.strip(),
    }),
    cvc: string()
        .label('CVC')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    hasSms: boolean(),
    hasNewsletter: boolean(),
});

type CheckoutOrderFormModel = InferType<typeof checkoutOrderFormSchema>;

export default CheckoutOrderFormModel;
