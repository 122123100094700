import formatPhoneNumber from '@/utils/formatPhoneNumber';
import CheckoutOrderFormModel from './CheckoutOrderFormModel';

type CheckoutOrderRequestModel = {
    billingFirstName: string;
    billingLastName: string;
    billingPhone: string;
    billingAddressLine_1: string;
    billingAddressLine_2?: string;
    billingCity: string;
    billingAddressType: string;
    billingCompany?: string;
    billingPostcode: string;
    billingState: string;
    billingCountry: string;
    number?: string;
    month?: number;
    year?: number;
    cvc?: string;
    paymentMethod?: string;
    sms?: boolean;
    newsletter?: boolean;
};

export default CheckoutOrderRequestModel;

export const makeCheckoutOrderRequest = (
    form: CheckoutOrderFormModel
): CheckoutOrderRequestModel => ({
    billingFirstName: form.firstName,
    billingLastName: form.lastName,
    billingPhone: formatPhoneNumber(form.phone),
    billingAddressLine_1: form.billingAddressLine_1,
    billingAddressLine_2: form.suite,
    billingCity: form.billingCity,
    billingAddressType: form.billingAddressType,
    billingCompany: form.companyName,
    billingPostcode: form.postcode,
    billingCountry: 'AU',
    billingState: form.billingState,
    number: form.number,
    // eslint-disable-next-line no-nested-ternary
    month: form.expiration
        ? form.expiration?.length === 3
            ? Number(form.expiration.replace(/\//g, '').slice(0, 1))
            : Number(form.expiration.replace(/\//g, '').slice(0, 2))
        : undefined,
    // eslint-disable-next-line no-nested-ternary
    year: form.expiration
        ? 2000 + Number(form.expiration.replace(/\//g, '').slice(-2))
        : undefined,
    cvc: form.cvc,
    paymentMethod: form.paymentMethod,
    sms: form.sms,
    newsletter: form.newsletter,
});
