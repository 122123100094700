import StateType from '@/types/StateType';
import formatPhoneNumber from '@/utils/formatPhoneNumber';
import CheckoutFormModel, { parseAddress } from './CheckoutFormModel';

type CheckoutRequestModel = {
    firstName: string;
    lastName: string;
    phone: string;
    password?: string;
    passwordConfirmation?: string;
    shippingAddressLine_1: string;
    shippingAddressLine_2?: string;
    shippingCity: string;
    shippingAddressType: string;
    shippingCompany?: string;
    shippingState: StateType;
    deliveryNotes?: string;
    billingAddressLine_1?: string;
    billingAddressLine_2?: string;
    billingCity?: string;
    billingAddressType?: string;
    billingCompany?: string;
    billingPostcode?: string;
    billingState?: string;
    billingCountry?: string;
    number?: string;
    month?: number;
    year?: number;
    cvc?: string;
    paymentMethod?: string;
    frequency?: number;
    sms?: boolean;
    newsletter?: boolean;
    discount?: string;
    choices?: any;
};

export default CheckoutRequestModel;

export const makeCheckoutRequest = (
    form: CheckoutFormModel
): CheckoutRequestModel => ({
    firstName: form.firstName,
    lastName: form.lastName,
    phone: formatPhoneNumber(form.phone),
    password: form.password,
    passwordConfirmation: form.password,
    shippingAddressLine_1: parseAddress(form.shippingAddressLine_1, form.suite),
    shippingAddressLine_2: form.suite,
    shippingCity: form.shippingCity,
    shippingAddressType: form.shippingAddressType,
    shippingCompany: form.companyName,
    shippingState: form.shippingState,
    deliveryNotes: form.deliveryInstructions,
    billingAddressLine_1:
        form.useShippingAddress === true
            ? parseAddress(form.shippingAddressLine_1, form.suite)
            : form.billingAddressLine_1,
    billingAddressLine_2:
        form.useShippingAddress === true ? form.suite : form.billingSuite,
    billingCity:
        form.useShippingAddress === true ? form.shippingCity : form.billingCity,
    billingAddressType:
        form.useShippingAddress === true
            ? form.shippingAddressType
            : form.billingAddressType,
    billingCompany:
        form.useShippingAddress === true
            ? form.companyName
            : form.billingCompanyName,
    billingPostcode:
        form.useShippingAddress === true ? form.postcode : form.billingPostcode,
    billingCountry:
        form.useShippingAddress === true ? 'AU' : form.billingCountry,
    billingState:
        form.useShippingAddress === true
            ? form.shippingState
            : form.billingState,
    number: form.number,
    // eslint-disable-next-line no-nested-ternary
    month: form.expiration
        ? form.expiration?.length === 3
            ? Number(form.expiration.replace(/\//g, '').slice(0, 1))
            : Number(form.expiration.replace(/\//g, '').slice(0, 2))
        : undefined,
    // eslint-disable-next-line no-nested-ternary
    year: form.expiration
        ? 2000 + Number(form.expiration.replace(/\//g, '').slice(-2))
        : undefined,
    cvc: form.cvc,
    paymentMethod: form.paymentMethod,
    frequency: form.frequency,
    sms: form.sms,
    newsletter: form.newsletter,
    discount: form.discount,
    choices: form.choices,
});
