import { InferType, object, string } from 'yup';
import messages from '@/utils/messages';
import passwordConfirmation from './rules/passwordConfirmation';

export const passwordResetFormSchema = object({
    token: string().label('Token').required(),
    email: string()
        .label('Email')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required(),
    password: string().label('Password').required(),
    passwordConfirmation: string()
        .label('Password confirmation')
        .required()
        .test(
            passwordConfirmation(
                'password',
                messages.errors.passwordConfirmation
            )
        ),
});

type PasswordResetFormModel = InferType<typeof passwordResetFormSchema>;

export default PasswordResetFormModel;
