import { useMutation, useQuery } from '@tanstack/react-query';
import { decamelize } from 'humps';
import client from './client';

export const fetchOrder = (order: any) =>
    client.get(`/api/orders/${order}/checkout/breakdown`);

export function useOrderpage(order: any) {
    const query = useQuery(
        ['order'],
        async () => {
            const { data } = await fetchOrder(order);
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: orderData, ...queryResult } = query;
    return { orderData, ...queryResult };
}

export const orderCheckout = (order: any, data: any) =>
    client.post<any>(`/api/orders/${order}/checkout`, data, {
        transformRequest: [
            (d: any) => {
                const payload: any = new FormData();
                Object.entries(d).forEach(([key, value]) => {
                    if (key === 'number' && value) {
                        payload.append('card[number]', value);
                    } else if (key === 'month' && value) {
                        payload.append('card[month]', value);
                    } else if (key === 'year' && value) {
                        payload.append('card[year]', value);
                    } else if (key === 'cvc' && value) {
                        payload.append('card[cvc]', value);
                    } else if (key === 'sms' && value) {
                        payload.append(decamelize(key), +value);
                    } else if (key === 'newsletter' && value) {
                        payload.append(decamelize(key), +value);
                    } else if (
                        value !== undefined &&
                        value !== false &&
                        value !== ''
                    ) {
                        payload.append(decamelize(key), value);
                    }
                });
                return payload;
            },
        ],
    });

export function useOrderCheckout(order: any) {
    return useMutation(async (payload: any) => {
        const { data } = await orderCheckout(order, payload);
        return data;
    });
}
