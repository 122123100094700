import clsx from 'clsx';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spring, animated } from 'react-spring';
import BillingDetailsCard from '@/components/cards/BillingDetailsCard';
import BillingProductListingCard from '@/components/cards/BillingProductListingCard';
import Button from '@/components/common/Button';
import { ChevronDownIcon } from '@/components/common/Icons';
import Wrapper from '@/components/common/Wrapper';
import CheckoutOrderForm from '@/components/forms/order/CheckoutOrderForm';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import AlertModal from '@/components/modals/AlertModal';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import CheckoutOrderFormModel, {
    checkoutOrderFormSchema,
} from '@/models/CheckoutOrderFormModel';
import { makeCheckoutOrderRequest } from '@/models/CheckoutOrderRequestModel';
import {
    useKlaviyoCheckoutError,
    useKlaviyoRequestError,
    useKlaviyoSubmitCheckoutRequest,
} from '@/services/klaviyo';
import { useOrderCheckout, useOrderpage } from '@/services/orderService';
import { useCreditBalance } from '@/services/profileService';
import {
    resetCheckoutError,
    selectCheckout,
    selectError,
    setUnauthenticated,
} from '@/store/getStarted/getStartedErrorSlice';
import { selectLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { useUser } from '@/store/user/userSlice';
import { transactionnalPrivateListen } from '@/utils/broadCastEvent';
import formatDollarAmount from '@/utils/formatDollarAmount';
import queryClient from '@/utils/queryClient';

export default function OrderCheckoutPage() {
    const [user] = useUser();
    const { height: windowHeight } = useWindowDimensions();
    const { creditBalanceResult } = useCreditBalance(!!user.hasPassword);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [open, setOpen] = useState<any>(false);
    const error = useSelector(selectError);
    const attemptedCheckout = useSelector(selectCheckout);
    const { order } = useParams();
    const loading = useSelector(selectLoading);
    const { orderData, isLoading, error: getOrderError } = useOrderpage(order);
    const [expanded, setExpanded] = useState(false);
    const { mutateAsync: orderCheckout, error: orderCheckoutError } =
        useOrderCheckout(order);

    const klaviyoCheckoutError = useKlaviyoCheckoutError;
    const klaviyoRequestError = useKlaviyoRequestError;
    const klaviyoSubmitCheckoutRequest = useKlaviyoSubmitCheckoutRequest;

    useEffect(() => {
        transactionnalPrivateListen(
            user?.id,
            'CheckedOut',
            `/dashboard/order-history`,
            'CheckedOut'
        );
        transactionnalPrivateListen(
            user?.id,
            'CheckoutError',
            `/dashboard/order-history`,
            'CheckoutError',
            dispatch
        );
    }, [dispatch, user]);

    useEffect(() => {
        if ((getOrderError as any)?.response?.status) {
            klaviyoRequestError(user, getOrderError);
            if (
                (getOrderError as any)?.response?.status === 401 ||
                (getOrderError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                window.location.href = '/dashboard/my-deliveries';
            }
        }
    }, [dispatch, getOrderError, user, klaviyoRequestError]);

    useEffect(() => {
        if (orderCheckoutError) {
            klaviyoCheckoutError(user, orderCheckoutError);
            setErrorMessage((orderCheckoutError as any).response.data.message);
            setOpen(true);
        }
        if (error) {
            klaviyoCheckoutError(user, error.error);
            if (error.error[Object.keys(error.error)[0]][0]) {
                setErrorMessage(error.error[Object.keys(error.error)[0]]);
                setOpen(true);
            } else {
                setOpen(false);
            }
        }
    }, [orderCheckoutError, user, klaviyoCheckoutError, error]);

    const handleSubmit = useHandleSubmit(
        checkoutOrderFormSchema,
        (v) => orderCheckout(makeCheckoutOrderRequest(v)),
        ({ helpers, values }) => {
            helpers.setTouched({}, false);
            helpers.setFieldValue('expiration', '');
            helpers.setFieldValue('cvc', '');
            helpers.setFieldValue('number', '');
            queryClient.invalidateQueries(['paymentMethods']);
            klaviyoSubmitCheckoutRequest(user, values);
        },
        dispatch
    );

    return (
        <Wrapper className="relative md:w-[calc(100%_-_40px)] md:max-w-[1054px]">
            <ThreeDotsLoader visible={loading || isLoading} />
            <div className="pt-12 text-center text-4xl font-medium uppercase">
                Pay an order
            </div>
            <div>
                <Formik<Partial<CheckoutOrderFormModel>>
                    initialValues={{
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        phone: user?.phone ? user?.phone.replace('+', '') : '',
                        country: 'Australia',
                        billingCity: '',
                        hasSms: user?.sms,
                        hasNewsletter: user?.newsletter,
                    }}
                    validationSchema={checkoutOrderFormSchema}
                    onSubmit={handleSubmit}
                >
                    <div className="flex w-full items-start pt-12">
                        <div className="grow lg:mr-2">
                            <CheckoutOrderForm
                                creditCardInvalid={error}
                                attemptedCheckout={attemptedCheckout}
                            />
                        </div>
                        {/* Mobile summary card */}
                        <aside className="fixed right-2 left-2 bottom-[-2px] z-50 flex flex-col border border-secondary bg-white p-4 shadow-block lg:hidden">
                            <button
                                className="flex h-[30px] items-center justify-between"
                                type="button"
                                onClick={() => setExpanded((s) => !s)}
                            >
                                <div className="py-1 text-3xl font-bold uppercase ">
                                    Your order
                                </div>
                                <ChevronDownIcon
                                    className={clsx(
                                        'h-5 w-8',
                                        !expanded && 'rotate-180'
                                    )}
                                />
                            </button>
                            <div className="pt-2">
                                <div
                                    className="inset-0 flex items-center"
                                    aria-hidden="true"
                                >
                                    <div className="w-full border-t-4 border-black" />
                                </div>
                            </div>
                            <Spring
                                to={{
                                    height: expanded ? windowHeight - 118 : 0,
                                    opacity: expanded ? 1 : 0,
                                }}
                            >
                                {({ height, opacity }: any) => (
                                    <animated.div
                                        style={{
                                            height,
                                            opacity,
                                        }}
                                        className="flex min-h-0 flex-col overflow-auto"
                                    >
                                        <div className="pt-2">
                                            {orderData?.[0]?.invoices?.[0]?.lines?.map(
                                                (e: any) => (
                                                    <BillingProductListingCard
                                                        key={e.sellable.id}
                                                        name={e.sellable.name}
                                                        productAmount={
                                                            e.quantity
                                                        }
                                                        productPrice={e.price}
                                                    />
                                                )
                                            )}
                                        </div>
                                        <BillingDetailsCard
                                            name="Subtotal"
                                            price={
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.subtotal
                                            }
                                        />
                                        <BillingDetailsCard
                                            name="Discount"
                                            price={
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.discounted
                                            }
                                        />
                                        {creditBalanceResult?.balance > 0 && (
                                            <div className="grid grid-cols-2 gap-6">
                                                <span className="text-base font-medium uppercase">
                                                    Credit
                                                </span>
                                                {creditBalanceResult?.balance >
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.total ? (
                                                    <span className="text-end text-base font-bold">
                                                        {formatDollarAmount(
                                                            orderData?.[0]
                                                                ?.invoices?.[0]
                                                                ?.total
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="text-end text-base font-bold">
                                                        {formatDollarAmount(
                                                            creditBalanceResult?.balance
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        <div
                                            role="separator"
                                            className="my-4 h-[1px] bg-secondary"
                                        />
                                        {creditBalanceResult?.balance > 0 ? (
                                            <div>
                                                {creditBalanceResult?.balance >
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.total ? (
                                                    <BillingDetailsCard
                                                        name="Total"
                                                        price={0}
                                                    />
                                                ) : (
                                                    <BillingDetailsCard
                                                        name="Total"
                                                        price={
                                                            orderData?.[0]
                                                                ?.invoices?.[0]
                                                                ?.total
                                                                ? orderData?.[0]
                                                                      ?.invoices?.[0]
                                                                      ?.total
                                                                : 0 -
                                                                  Number(
                                                                      creditBalanceResult?.balance
                                                                  )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <BillingDetailsCard
                                                name="Total"
                                                price={
                                                    orderData?.[0]
                                                        ?.invoices?.[0]?.total
                                                }
                                            />
                                        )}
                                        <div
                                            role="separator"
                                            className="my-4 h-[5px] bg-secondary"
                                        />
                                    </animated.div>
                                )}
                            </Spring>
                        </aside>
                        {/* Desktop summary card */}
                        <aside className="mb-12 mr-3.5 hidden w-1/5 min-w-[300px] flex-col border border-secondary bg-white p-4 shadow-block lg:flex">
                            <h3 className="text-3xl font-bold uppercase">
                                Your order
                            </h3>
                            <div className="max-h-[250px] overflow-auto pt-2">
                                {orderData?.[0]?.invoices?.[0]?.lines?.map(
                                    (e: any) => (
                                        <BillingProductListingCard
                                            key={e.sellable.id}
                                            name={e.sellable.name}
                                            productAmount={e.quantity}
                                            productPrice={e.price}
                                        />
                                    )
                                )}
                            </div>
                            <BillingDetailsCard
                                name="Subtotal"
                                price={orderData?.[0]?.invoices?.[0]?.subtotal}
                            />
                            <BillingDetailsCard
                                name="Discount"
                                price={
                                    orderData?.[0]?.invoices?.[0]?.discounted
                                }
                            />
                            {creditBalanceResult?.balance > 0 && (
                                <div className="grid grid-cols-2 gap-6">
                                    <span className="text-base font-medium uppercase">
                                        Credit
                                    </span>
                                    {creditBalanceResult?.balance >
                                    orderData?.[0]?.invoices?.[0]?.total ? (
                                        <span className="text-end text-base font-bold">
                                            {formatDollarAmount(
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.total
                                            )}
                                        </span>
                                    ) : (
                                        <span className="text-end text-base font-bold">
                                            {formatDollarAmount(
                                                creditBalanceResult?.balance
                                            )}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div
                                role="separator"
                                className="my-4 h-[1px] bg-secondary"
                            />
                            {creditBalanceResult?.balance > 0 ? (
                                <div>
                                    {creditBalanceResult?.balance >
                                    orderData?.[0]?.invoices?.[0]?.total ? (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={0}
                                        />
                                    ) : (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={
                                                orderData?.[0]?.invoices?.[0]
                                                    ?.total
                                                    ? orderData?.[0]
                                                          ?.invoices?.[0]?.total
                                                    : 0 -
                                                      Number(
                                                          creditBalanceResult?.balance
                                                      )
                                            }
                                        />
                                    )}
                                </div>
                            ) : (
                                <BillingDetailsCard
                                    name="Total"
                                    price={orderData?.[0]?.invoices?.[0]?.total}
                                />
                            )}
                            <div
                                role="separator"
                                className="my-4 h-[5px] bg-secondary"
                            />
                        </aside>
                    </div>
                </Formik>
            </div>
            <RedirectAfterErrorModal />

            {open && errorMessage && (
                <AlertModal open={open} onClose={setOpen} title={errorMessage}>
                    <div className="mt-8 flex flex-col">
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetCheckoutError());
                                setOpen(false);
                                setErrorMessage(null);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
        </Wrapper>
    );
}
